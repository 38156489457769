import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import parse from 'html-react-parser';
import FormWidget from "../consultora-fm-components/form-widget";

class ContactInfo extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL + '/'

        return <div className="ltn__contact-address-area mb-90 mt-60">
            <div className="container">
                <div className="row">
                    <div className="row flex-column col-lg-6">
                        <div className="col">
                            <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                                <div className="ltn__contact-address-icon">
                                    <img src={require('../../assets/img/email.png')} alt="Email"/>
                                </div>
                                <h3>Email</h3>
                                <p>consultorafm@gmail.com</p>
                            </div>
                        </div>
                        <div className="col">
                            <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                                <div className="ltn__contact-address-icon">
                                    <img src={require('../../assets/img/telephone-call.png')} alt="Teléfono"/>
                                </div>
                                <h3>Teléfono</h3>
                                <p>+54 9 11 5853-4313</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 flex-1">
                        <FormWidget category="Contact page" />
                    </div>
                </div>

            </div>
        </div>
    }
}

export default ContactInfo