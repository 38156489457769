import React from 'react';
import "../../assets/css/loader.css";


const Loader = () => {
    return (
        <div>
            <div className="wrapper-loader">
                <div className="loader-circle"></div>
                <div className="loader-circle"></div>
                <div className="loader-circle"></div>
                <div className="shadow"></div>
                <div className="shadow"></div>
                <div className="shadow"></div>
            </div>
            <div className="h-100 w-100 bg-dark position-fixed backdrop-loader"></div>

        </div>

    );
};

export default Loader;
