import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import parse from 'html-react-parser';

class Testimonial extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL + '/'
        let imagealt = 'image'

        return <div className="ltn__testimonial-area section-bg-1--- bg-image-top pt-115 pb-70"
                    data-bs-bg={publicUrl + "assets/img/bg/20.jpg"}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title-area ltn__section-title-2--- text-center">
                            <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Nuestra
                                reputación</h6>
                            <h1 className="section-title">Lo que dicen nuestros clientes</h1>
                        </div>
                    </div>
                </div>
                <div className="row ltn__testimonial-slider-5-active slick-arrow-1">
                    <div className="col-lg-4">
                        <div className="ltn__testimonial-item ltn__testimonial-item-7">
                            <div className="ltn__testimoni-info">
                                <p><i className="flaticon-left-quote-1"/>
                                   Vinimos del exterior por 4 meses y alquilamos a través de Consultora FM un hermoso 2 ambientes en Palermo. El departamento estaba nuevo, y el proceso de mudanza impecable.
                                </p>
                                <div className="ltn__testimoni-info-inner">
                                    {/*<div className="ltn__testimoni-img">*/}
                                    {/*    <img src={publicUrl + "assets/img/testimonial/1.jpg"} alt="#"/>*/}
                                    {/*</div>*/}
                                    <div className="ltn__testimoni-name-designation">
                                        <h5>Eric Lehmann</h5>
                                        <label>Alquiler</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="ltn__testimonial-item ltn__testimonial-item-7">
                            <div className="ltn__testimoni-info">
                                <p><i className="flaticon-left-quote-1"/>
                                   I was looking into flats to buy in Buenos Aires, since my partner is Argentinean. The level of attention, the care for the customer and the trust that Mónica inspires is certainly something to recommend.
                                </p>
                                <div className="ltn__testimoni-info-inner">
                                    {/*<div className="ltn__testimoni-img">*/}
                                    {/*    <img src={publicUrl + "assets/img/testimonial/1.jpg"} alt="#"/>*/}
                                    {/*</div>*/}
                                    <div className="ltn__testimoni-name-designation">
                                        <h5>Susanne Kerner</h5>
                                        <label>Compradora</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="ltn__testimonial-item ltn__testimonial-item-7">
                            <div className="ltn__testimoni-info">
                                <p><i className="flaticon-left-quote-1"/>
                                   Mónica nos tasó nuestro departamento en Recoleta, lo publicó y nos asesoró de acuerdo a las posibilidades del mercado. 100% confiable y transparente.
                                </p>
                                <div className="ltn__testimoni-info-inner">
                                    {/*<div className="ltn__testimoni-img">*/}
                                    {/*    <img src={publicUrl + "assets/img/testimonial/1.jpg"} alt="#"/>*/}
                                    {/*</div>*/}
                                    <div className="ltn__testimoni-name-designation">
                                        <h5>Agustín Chiarella</h5>
                                        <label>Vendedor</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    }
}

export default Testimonial