import React from 'react';
import {Divider} from "@mui/material";

const HerediaPoint = () => {

    return (
        <div className="flex-column justify-content-center align-items-center d-flex bg-dark py-5">
            <div>
                <div className="row border-top w-75 m-auto mb-4"/>
                <h2 className="text-uppercase mb-3 text-center text-light">Nuevos departamentos a estrenar</h2>
                <h3 className="text-center text-secondary ltn__secondary-color">Heredia y Av. de los Incas, Belgrano R</h3>
                <div className="col-12 justify-content-center d-flex mb-4 w-75 m-auto">
                    <a target="_blank" href="https://heredia-point.com.ar">
                        <img
                            src={`${process.env.REACT_APP_SERVER_URL}/assets/922c2aed-0350-4a46-b6a0-c4a0604d763b?format=webp`}
                            alt="Heredia Point"
                        />
                    </a>
                </div>

                <div className="btn-wrapper go-top text-center">
                    <a href="https://heredia-point.com.ar/" className="btn btn-effect-3 btn-consultora-primary">
                        Ver proyecto <i className="icon-next"/>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default HerediaPoint;
