import React from 'react';

const ReactImageGallery = ({images}) => {


    console.log(images, "IMG")

    return <></>;
};

export default ReactImageGallery;
